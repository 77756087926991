@media screen and (max-width: 576px) {
    .page-header .container h6.category-absolute {
      width: 90%; }
    .form-horizontal .col-form-label, .form-horizontal .label-on-right {
      text-align: inherit;
      padding-top: 0; }
      .form-horizontal .col-form-label code, .form-horizontal .label-on-right code {
        padding: 0 10px; } 
}

.index-page .page-header {
    height: 1000vh; }

/* .page-header .mint-container{
  position: relative;
  top: 25%;
} */

.index-page .page-header .container > .content-center.brand .h1-seo,
    .index-page .page-header .container > .content-center.brand h3 {
      color: #ffffff;
      font-weight: 600;
      text-transform: capitalize; 
}

  
.index-page .page-header .container > .content-center.brand .h1-seo span,
      .index-page .page-header .container > .content-center.brand h3 span {
        font-weight: 600; 
}

.index-page .page-header .category-absolute {
    position: absolute;
    top: 100vh;
    margin-top: -60px;
    padding: 0 15px;
    width: 100%;
    color: rgba(255, 255, 255, 0.5); 
}
  
.index-page .squares {
    animation: move-left-right 1s infinite;
    background: #ba54f5;
    background: -webkit-linear-gradient(0deg, #ba54f5 0%, #e14eca 100%);
    background: -o-linear-gradient(0deg, #ba54f5 0%, #e14eca 100%);
    background: -moz-linear-gradient(0deg, #ba54f5 0%, #e14eca 100%);
    background: linear-gradient(0deg, #ba54f5 0%, #e14eca 100%);
    position: absolute;
    transition: 0.5s ease-out;
    overflow: hidden;
    border-radius: 20%; }
    .index-page .squares.square1 {
        animation: move-left-right 4s infinite;
        height: 300px;
        width: 300px;
        opacity: 0.5;
        left: 3%;
        top: -21%; }
    .index-page .squares.square2 {
        animation: move-left-right 6s infinite;
        height: 400px;
        width: 400px;
        opacity: 0.4;
        right: -5%;
        top: -12%; }
    .index-page .squares.square3 {
        animation: move-left-right 5s infinite;
        height: 200px;
        width: 200px;
        opacity: 0.1;
        left: -5%;
        bottom: 0%; }
    .index-page .squares.square4 {
        animation: move-left-right 10s infinite;
        height: 100px;
        width: 100px;
        opacity: 0.9;
        right: 27%;
        top: 70%; }
    .index-page .squares.square5 {
        animation: move-left-right 6s infinite;
        height: 250px;
        width: 250px;
        opacity: 0.1;
        left: 32%;
        bottom: 29%; }
    .index-page .squares.square6 {
        animation: move-left-right 9s infinite;
        left: 10%;
        top: 35%;
        height: 80px;
        width: 80px;
        opacity: 0.8; }
    .index-page .squares.square7 {
        animation: move-left-right 3s infinite;
        width: 300px;
        height: 300px;
        right: -5%;
        bottom: 0%;
        opacity: 0.1; }

.page-header{ 
    background: radial-gradient(ellipse at bottom, #292D61 30%, #171941 80%); 
    height: 500px;}

    .page-header {
        min-height: 100vh;
        max-height: 999px;
        padding: 0;
        color: #ffffff;
        position: relative;
        overflow: hidden; }
        .page-header .page-header-image {
          position: absolute;
          background-size: cover;
          background-position: center center;
          width: 100%;
          height: 100%;
          z-index: -1; }
        .page-header > .content {
          margin-top: 11%;
          text-align: center;
          margin-bottom: 50px; }
        .signup-page .page-header {
          max-height: fit-content; }
        .page-header .content-center {
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: 2;
          -ms-transform: translate(-50%, -50%);
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          text-align: center;
          color: #ffffff;
          padding: 0 15px;
          width: 100%;
          max-width: 880px; }
          .page-header .content-center.brand {
            width: 47%; }
        .page-header footer {
          position: absolute;
          bottom: 0;
          width: 100%; }
        .page-header .container {
          height: 100%;
          z-index: 1; }
        .page-header .category,
        .page-header .description {
          color: rgba(255, 255, 255, 0.8); }
        .page-header.page-header-small {
          min-height: 60vh;
          max-height: 440px; }
        .page-header.page-header-mini {
          min-height: 40vh;
          max-height: 340px; }
        .page-header .title {
          margin-bottom: 15px; }
        .page-header .title + h4 {
          margin-top: 10px; }
        .page-header.header-filter:after {
          background: rgba(0, 0, 0, 0.5); }






.index-page .squares {
background: #3358f4;
background: -webkit-linear-gradient(0deg, #3358f4 0%, #1d8cf8 100%);
background: -o-linear-gradient(0deg, #3358f4 0%, #1d8cf8 100%);
background: -moz-linear-gradient(0deg, #3358f4 0%, #1d8cf8 100%);
background: linear-gradient(0deg, #3358f4 0%, #1d8cf8 100%); }          