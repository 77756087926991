@keyframes spinner {
  from {
    background: rgba(16, 185, 83, 0);
  }
  50% {
    background: rgba(16, 83, 185, 1);
  }
  to {
    background: rgba(16, 185, 83, 0);
  }
}

.spinner-box {
  margin-top: 50px;
  height: 80px;
  position: relative;

  filter: drop-shadow(1px 2px 12px rgba(4, 62, 27, 0.08));

  .spinner-item {
    display: block;
    position: absolute;
    background: #1053B9;
    border: 2px solid #ffffff87;
    box-shadow: 1px 2px 12px rgba(4, 62, 27, 0.08);
    border-radius: 28px;
    width: 7.65px;
    height: 20px;

    top: calc(50% - 18px);
    left: calc(50% - 10px);

    $angle: calc(360 / 12);
    $rot: 0;

    $color: (1, 1, 1, 1, 0.8, 0.64, 0.48, 0.32, 0.24, 0.16, 0.08, 0.02);

    @for $i from 1 through 12 {
      &:nth-of-type(#{$i}) {
        $delay: calc($i / 12) * 2000ms;
        transform: rotate($rot * 1deg) translate(40px) rotate(90deg);
        background: rgba(16, 83, 185, nth($color, $i));
        animation: spinner 2s forwards $delay infinite ease-in-out;
      }
      $rot: $rot + $angle;
    }
  }
}
