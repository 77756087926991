.collection-item {
  display: flex;
  flex-direction: column;

  background: #ffffff;
  border: 1px solid #d6d6d6;
  box-sizing: border-box;
  box-shadow: 10px 13px 15px -8px rgba(0, 0, 255, 0.25);
  border-radius: 6.9px;
  min-width:auto;

  align-items: center;

  .title {
    // display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 10px 15px;

    .name {
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.04em;

      color: #9d9d9d;
      
      strong {
        color: black;
      }
    }
    .type {
      display: block;
      padding: 5px 10px;
      background: #0911b3;
      border: 1px solid #0911b3;
      box-sizing: border-box;
      box-shadow: 2px 4px 4px rgba(4, 40, 113, 0.12);
      border-radius: 12.69px;

      font-weight: bold;
      font-size: 14px;
      line-height: 17px;

      letter-spacing: 0.01em;
      text-transform: uppercase;

      color: #e8ffef;
    }
  }
  .content {
    padding: 10px 15px;
    span {
      display: flex;
      justify-content: left;
      align-items: center;

      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
      color: #000000;

      text-align: left;

      padding: 10px 5px;
      border-bottom: 0.5px solid #d9d9d9;

      &:last-of-type {
        border-bottom: none;
      }
      svg {
        margin-right: 10px;
      }
    }

    img {
      width: 100%;
      border-radius: 4px;
    }
  }
}
