.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
text-align: justify !important; }

.text-wrap {
white-space: normal !important; }

.text-nowrap {
white-space: nowrap !important; }

.text-truncate {
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap; }

.text-left {
text-align: left !important; }

.text-right {
text-align: right !important; }

.text-center {
text-align: center !important; }

.navbar {
  display:flex;
  flex-direction:column;
}


@media screen and (max-width: 991px) {
    .navbar .navbar-translate {
      width: 100%;
      position: relative;
      display: flex;
      justify-content: space-between !important; }
    .navbar-collapse {
      position: absolute;
      width: calc(100% - 1.4rem);
      height: auto !important;
      left: 0;
      top: 0;
      margin: 0.7rem;
      background: #1f2251;
      border-radius: 0.2857rem;
      padding: 1.4rem; } 
}

@media screen and (max-width: 991px) {

    .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
      padding-right: 15px;
      padding-left: 15px; }
    .navbar .navbar-collapse .input-group {
      margin: 0;
      margin-top: 5px; }
    .navbar .navbar-collapse.collapsing, .navbar .navbar-collapse.collapse {
      animation: show_navbar_collapse .2s ease forwards; }
    .navbar .navbar-collapse.collapsing-out {
      animation: hide_navbar_collapse .2s ease forwards; }
    .navbar .navbar-collapse .navbar-collapse-header {
      display: block;
      padding-bottom: 1rem; }
      .navbar .navbar-collapse .navbar-collapse-header .collapse-brand {
        font-size: 1.2495rem;
        color: #ffffff; 
        }
        .navbar .navbar-collapse .navbar-collapse-header .collapse-brand a {
          font-weight: 600; }
      .navbar .navbar-collapse .navbar-collapse-header .collapse-close .navbar-toggler {
        color: #ffffff;
        font-size: 1.2495rem; 
       }
       .navbar-toggler:hover {
        animation: rotate-close 1s ease;
       }

    .navbar .navbar-collapse .navbar-nav li {
      padding: 0px; }
    .navbar .navbar-nav .btn {
      margin-left: -3px;
      display: flex; }
      .navbar .navbar-nav .btn i {
        margin-right: 12px; }
      .navbar .navbar-nav .btn span {
        margin: 0;
        text-transform: uppercase;
        font-weight: 300; }
        .navbar .navbar-nav .btn span, .navbar .navbar-nav .btn span:hover, .navbar .navbar-nav .btn span:focus, .navbar .navbar-nav .btn span:active, .navbar .navbar-nav .btn span:active:focus {
          color: #222a42 !important; 
        }
    .navbar .navbar-nav a.nav-link i {
      opacity: 1;
      margin-right: 5px; }
    .navbar .navbar-nav a.nav-link p {
      display: inline-block;
      margin-left: 7px;
      color: #ffffff; }
    .navbar .navbar-nav .modal-search .modal-dialog {
      padding: 0 40px; }
    .navbar .navbar-nav .dropdown {
      margin: 5px 0; }
      .navbar .navbar-nav .dropdown .dropdown-menu .dropdown-item {
        margin-top: 0;
        padding-left: 24px; }
    .navbar .dropdown.show .dropdown-menu {
      display: block; }
    .navbar .dropdown .dropdown-menu {
      display: none; }
      .navbar .dropdown .dropdown-menu li a {
        color: #222a42; }
    .navbar .dropdown.show .dropdown-menu,
    .navbar .dropdown .dropdown-menu {
      background-color: transparent;
      border: 0;
      transition: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      width: auto;
      margin: 0px 0.7rem;
      margin-top: 0px; }
      .navbar .dropdown.show .dropdown-menu:before,
      .navbar .dropdown .dropdown-menu:before {
        display: none; }
    .navbar .dropdown-menu .dropdown-item:focus,
    .navbar .dropdown-menu .dropdown-item:hover {
      color: #ffffff; }
    .navbar.bg-white .dropdown-menu .dropdown-item:focus,
    .navbar.bg-white .dropdown-menu .dropdown-item:hover {
      color: #344675; }
    .navbar button.navbar-toggler[data-target="#navigation"] {
      padding-top: 0; }
    .navbar .navbar-toggler-bar {
      display: block;
      position: relative;
      width: 22px;
      height: 1px;
      border-radius: 1px;
      background: #ffffff; }
      .navbar .navbar-toggler-bar.navbar-kebab {
        height: 4px;
        width: 4px;
        margin-bottom: 3px;
        border-radius: 50%; }
      .navbar .navbar-toggler-bar + .navbar-toggler-bar {
        margin-top: 7px; }
      .navbar .navbar-toggler-bar + .navbar-toggler-bar.navbar-kebab {
        margin-top: 0px; }
      .navbar .navbar-toggler-bar.bar2 {
        width: 17px;
        transition: width .2s linear; }
    .navbar.bg-white:not(.navbar-transparent) .navbar-toggler-bar {
      background-color: #344675; }
    .navbar .navbar-brand {
      margin-left: 20px;
      position: relative; }
    .navbar-nav .nav-link i.fa,
    .navbar-nav .nav-link i.tim-icons {
      opacity: .5; }
    
    @keyframes rotate-close {
      0% {
        top: 0px;
        transform: rotate(0deg); }
      100% {
        top: 6px;
        transform: rotate(360deg); }
    }
    @keyframes topbar-x {
      0% {
        top: 0px;
        transform: rotate(0deg); }
      45% {
        top: 6px;
        transform: rotate(145deg); }
      75% {
        transform: rotate(130deg); }
      100% {
        transform: rotate(135deg); } }
    @-webkit-keyframes topbar-x {
      0% {
        top: 0px;
        -webkit-transform: rotate(0deg); }
      45% {
        top: 6px;
        -webkit-transform: rotate(145deg); }
      75% {
        -webkit-transform: rotate(130deg); }
      100% {
        -webkit-transform: rotate(135deg); } }
    @-moz-keyframes topbar-x {
      0% {
        top: 0px;
        -moz-transform: rotate(0deg); }
      45% {
        top: 6px;
        -moz-transform: rotate(145deg); }
      75% {
        -moz-transform: rotate(130deg); }
      100% {
        -moz-transform: rotate(135deg); } }
    @keyframes topbar-back {
      0% {
        top: 6px;
        transform: rotate(135deg); }
      45% {
        transform: rotate(-10deg); }
      75% {
        transform: rotate(5deg); }
      100% {
        top: 0px;
        transform: rotate(0); } }
    @-webkit-keyframes topbar-back {
      0% {
        top: 6px;
        -webkit-transform: rotate(135deg); }
      45% {
        -webkit-transform: rotate(-10deg); }
      75% {
        -webkit-transform: rotate(5deg); }
      100% {
        top: 0px;
        -webkit-transform: rotate(0); } }
    @-moz-keyframes topbar-back {
      0% {
        top: 6px;
        -moz-transform: rotate(135deg); }
      45% {
        -moz-transform: rotate(-10deg); }
      75% {
        -moz-transform: rotate(5deg); }
      100% {
        top: 0px;
        -moz-transform: rotate(0); } }
    @keyframes bottombar-x {
      0% {
        bottom: 0px;
        transform: rotate(0deg); }
      45% {
        bottom: 6px;
        transform: rotate(-145deg); }
      75% {
        transform: rotate(-130deg); }
      100% {
        transform: rotate(-135deg); } }
    @-webkit-keyframes bottombar-x {
      0% {
        bottom: 0px;
        -webkit-transform: rotate(0deg); }
      45% {
        bottom: 6px;
        -webkit-transform: rotate(-145deg); }
      75% {
        -webkit-transform: rotate(-130deg); }
      100% {
        -webkit-transform: rotate(-135deg); } }
    @-moz-keyframes bottombar-x {
      0% {
        bottom: 0px;
        -moz-transform: rotate(0deg); }
      45% {
        bottom: 6px;
        -moz-transform: rotate(-145deg); }
      75% {
        -moz-transform: rotate(-130deg); }
      100% {
        -moz-transform: rotate(-135deg); } }
    @keyframes bottombar-back {
      0% {
        bottom: 6px;
        transform: rotate(-135deg); }
      45% {
        transform: rotate(10deg); }
      75% {
        transform: rotate(-5deg); }
      100% {
        bottom: 0px;
        transform: rotate(0); } }
    @-webkit-keyframes bottombar-back {
      0% {
        bottom: 6px;
        -webkit-transform: rotate(-135deg); }
      45% {
        -webkit-transform: rotate(10deg); }
      75% {
        -webkit-transform: rotate(-5deg); }
      100% {
        bottom: 0px;
        -webkit-transform: rotate(0); } }
    @-moz-keyframes bottombar-back {
      0% {
        bottom: 6px;
        -moz-transform: rotate(-135deg); }
      45% {
        -moz-transform: rotate(10deg); }
      75% {
        -moz-transform: rotate(-5deg); }
      100% {
        bottom: 0px;
        -moz-transform: rotate(0); } }
    @-webkit-keyframes fadeIn {
      0% {
        opacity: 0; }
      100% {
        opacity: 1; } }
    @-moz-keyframes fadeIn {
      0% {
        opacity: 0; }
      100% {
        opacity: 1; } }
    @keyframes fadeIn {
      0% {
        opacity: 0; }
      100% {
        opacity: 1; } 
    } 
}
@media screen and (min-width: 992px) {
    .navbar-collapse {
      
      background: none !important; }
    .navbar .navbar-toggle {
      display: none; }
    .container{
      display: flex;
      align-items: center;
    }

    


    /* .navbar-nav .nav-link.profile-photo {
      padding: 0;
      margin: 7px 0.7rem; } */
    /* .navbar .caret {
      position: absolute;
      left: 80%;
      top: 55%;
      margin-left: 0; } */
    .navbar-expand-lg .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; 
    } 
}  

@media screen and (max-width: 991px) {
    .navbar .navbar-translate {
      width: 100%;
      position: relative;
      display: flex;
      justify-content: space-between !important; }
    .navbar-collapse {
      position: absolute;
      width: calc(100% - 1.4rem);
      height: auto !important;
      left: 0;
      top: 0;
      margin: 0.7rem;
      background: #1f2251;
      border-radius: 0.2857rem;
      padding: 1.4rem; } 
}
  
@media screen and (max-width: 576px) {
.navbar[class*='navbar-toggleable-'] .container {
    margin-left: 0;
    margin-right: 0; }
}


/* .navigation-example .navbar {
    z-index: 2; 
} */
  



/* .btn-default {
    color: #ffffff;
    background-color: #344675;
    border-color: #344675;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); }
.btn-default:hover {
    color: #ffffff;
    background-color: #28365b;
    border-color: #243152; }
.btn-default:focus, .btn-default.focus {
    color: #ffffff;
    background-color: #28365b;
    border-color: #243152;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(82, 98, 138, 0.5); }
.btn-default.disabled, .btn-default:disabled {
    color: #ffffff;
    background-color: #344675;
    border-color: #344675; }
.btn-default:not(:disabled):not(.disabled):active, .btn-default:not(:disabled):not(.disabled).active,
.show > .btn-default.dropdown-toggle {
    color: #ffffff;
    background-color: #243152;
    border-color: #202c49; }
    .btn-default:not(:disabled):not(.disabled):active:focus, .btn-default:not(:disabled):not(.disabled).active:focus,
    .show > .btn-default.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(82, 98, 138, 0.5); } */

.nav-item{
  color:white;
}
.navbar {
    padding: 0.9375rem;
    width: 100%;
    z-index: 1050;
    background: #e14eca; }
    /* .navbar .photo {
      display: inline-block;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      vertical-align: middle;
      overflow: hidden; }
      .navbar .photo img {
        width: 100%; } */
    .navbar .navbar-nav .nav-item .nav-link {
      position:relative;
      color: inherit;
      line-height: 20px;
      display: inline-flex; }
      .navbar .navbar-nav .nav-item .nav-link:hover {
        cursor:pointer;
        color: rgba(225, 78, 202, 1); }
    .navbar .navbar-wrapper {
      display: flex;
      align-items: center; }
    .navbar .navbar-text {
      color: #00ffff; }
    .navbar .btn {
      margin: 0 5px 0 10px; }
    .navbar p {
      margin: 0; }
    .navbar.navbar-absolute {
      position: absolute;
      z-index: 1050; }
    .navbar.navbar-transparent {
      background: transparent !important;
      padding-top: 0.5rem; }
    /* .navbar.bg-white .navbar-nav .search-bar.input-group i {
      color: #222a42; }
    .navbar.bg-white .navbar-nav .search-bar.input-group .form-control {
      background: rgba(34, 42, 66, 0.1);
      border-radius: 4px; } */
    /* .navbar.bg-white .navbar-nav a.nav-link {
      color: #222a42 !important; }
      .navbar.bg-white .navbar-nav a.nav-link p {
        color: #222a42; }
    .navbar.bg-white .navbar-text, .navbar.bg-white .navbar-brand {
      color: #222a42; }
    .navbar.bg-white .form-control {
      color: #222a42 !important; }
    .navbar.bg-white .form-control::placeholder {
      color: #9A9A9A !important; }
    .navbar.bg-dark {
      background: #222a42 !important; }
    .navbar.bg-primary {
      background-color: #e14eca !important; }
    .navbar.bg-warning {
      background-color: #ff8d72 !important; } */
    .navbar.bg-info {
      background-color: var(--bs-info) !important;  /* #1d8cf8 */
      animation: fadeInDown 0.3s linear;
    }
    /* .navbar.bg-success {
      background-color: #00bf9a !important; }
    .navbar.bg-danger {
      background-color: #fd5d93 !important; } */
    .navbar .navbar-brand {
      position: relative;
      padding-top: .3125rem;
      padding-bottom: .3125rem;
      color: #ffffff;
      text-transform: capitalize;
      font-size: 1rem; }
      .navbar .navbar-brand span {
        font-weight: 600; }
    .navbar .navbar-toggle button:focus, .navbar .navbar-toggler {
      outline: none; }
  
  .navbar-minimize-fixed {
    position: fixed;
    margin-left: 40px;
    margin-top: 14px;
    transition: 0.3s ease;
    color: white;
    z-index: 20;
    opacity: 0;
    transition: 0.2s ease; }
    .navbar-minimize-fixed button i {
      font-size: 18px; }
  
  /* .notification {
    background: #fd5d93;
    color: #ffffff;
    border-radius: 0.875rem;
    height: 6px;
    width: 6px;
    position: absolute;
    text-align: center;
    font-size: 12px;
    font-weight: 800;
    top: 10px;
    right: 10px;
    border: 1px solid #fd5d93; } */
  
  .navbar-nav li {
    padding: 0 10px; }
    .navbar-nav li a {
      color: #ffffff; }
    .navbar-nav li i {
      vertical-align: middle;
      font-size: 18px;
      padding-right: 5px; }
  
  .navbar-collapse-header {
    display: none; }

/* .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #4950FF;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-link p{
  /* padding: 0.5rem 1rem;  
  color: whitesmoke
}
.nav-link p:hover, .nav-link p:focus {
    text-decoration: none; 
    color: orange
  } */

.btn.wallet-connected{
  background:#081d65;border:1px solid #26cb5c;
  box-sizing:border-box;border-radius:5px;color:#22b55c;
  width:13rem;

}
/* @media (max-width: 1024px){
  .btn.wallet-connected{
    background:#ff4848;border:1px solid #ff4848;
    color:white
  }
  .btn.wallet-connected a{display:none}
  .btn.wallet-connected::after{content:'Disconnect Wallet'}
} */
.btn.wallet-connected:hover{
  background:#ff4848;border:1px solid #ff4848;
  color:white;
  box-sizing:border-box;
  width:13rem;
}
.btn.wallet-connected:hover a{
  display:none
}
.btn.wallet-connected:hover::after{content:'Disconnect Wallet'}




/* @media screen and (max-width: 991px) {
    .profile-photo .profile-photo-small {
        margin-left: -2px; }
    .button-dropdown {
        display: none; }
    #searchModal .modal-dialog {
        margin: 20px; }
    #minimizeSidebar {
        display: none; } }
    
@media screen and (max-width: 768px) {
    .landing-page .section-story-overview .image-container:nth-child(2) {
        margin-left: 0;
        margin-bottom: 30px; } }
    
@media screen and (max-width: 576px) {
    .page-header .container h6.category-absolute {
        width: 90%; }
    .form-horizontal .col-form-label, .form-horizontal .label-on-right {
        text-align: inherit;
        padding-top: 0; }
        .form-horizontal .col-form-label code, .form-horizontal .label-on-right code {
        padding: 0 10px; } } */



.btn-primary.animation-on-hover:hover {
    background-position: bottom left;
    transition: 0.3s ease-in-out; }
  .btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .btn-primary:active:focus, .btn-primary:active:hover, .btn-primary.active:focus, .btn-primary.active:hover {
    background-color: #ba54f5 !important;
    background-image: linear-gradient(to bottom left, #e14eca, #ba54f5, #e14eca) !important;
    background-image: -webkit-linear-gradient(to bottom left, #e14eca, #ba54f5, #e14eca) !important;
    background-image: -o-linear-gradient(to bottom left, #e14eca, #ba54f5, #e14eca) !important;
    background-image: -moz-linear-gradient(to bottom left, #e14eca, #ba54f5, #e14eca) !important;
    color: #ffffff;
    box-shadow: none; }
  .btn-primary:active {
    box-shadow: none !important;
    transform: translateY(1px) !important;
    transition: all .15s ease; }
  .btn-primary:not([data-action]):hover {
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
    transform: translateY(-1px);
    -webkit-transform: translateY(-1px); }


@keyframes fadeInDown {
  from {
    opacity: 0.5;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); 
  } 
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }
            
            /* .fadeInDown {
              -webkit-animation-name: fadeInDown;
              animation-name: fadeInDown; } */

@keyframes show_navbar_collapse {
  0% {
      opacity: 0;
      transform: scale(0.95);
      transform-origin: 100% 0; }
  100% {
      opacity: 1;
      transform: scale(1); } 
}

@keyframes hide_navbar_collapse {
from {
  opacity: 1;
  transform: scale(1);
  transform-origin: 100% 0; }
to {
  opacity: 0;
  transform: scale(0.95); } 
}

@keyframes move-left-right {
  0% {
    transform: translateX(-10px); }
  50% {
    transform: translateX(10px); }
  100% {
    transform: translateX(-10px); } }