.mint-container {
    height:fit-content;
    border: 2px solid var(--bs-warning);
    width: max-content;
    padding: 10px 10px;
    border-radius: 10px;
    display:inline-block;
    top: 9%;
    right: 13%;
    position: fixed;
    background-color: rgba(0, 0, 67, 0.8);

}

.row {
    color: aliceblue
}


/* .mint-container .btn {
    background-color: #0a58ca;
    background-image:none;
} */