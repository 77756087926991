@import 'src/css/mixin/media';

.purchase-page {
  background: linear-gradient(173.65deg, #020b29 -9.4%, #041549 65.95%);
}
.purchase-loading main {
  min-height: calc(100vh - 445px);
  background: radial-gradient(ellipse at bottom, #292D61 30%, #171941 80%); 
  padding: 50px 0 50px 0;
  .container {
    min-height: calc(100vh - 445px);

    display: flex;
    align-items: center;
    justify-content: center;
  }
  .purchase-box {
    background: #ffffff;
    box-shadow: 12px 21px 17px rgba(0, 0, 0, 0.25);
    border-radius: 3px;

    padding: 90px 65px 90px 65px;
    h2 {
      font-size: 48px;
      color: #000414;
    }

    h3 {
      margin-left: auto;
      margin-right: auto;

      margin-top: 50px;
      max-width: 760px;

      font-weight: 500;
      font-size: 18px;
      color: #000000;
    }

    .compute-result {
      display: flex;
      justify-content: space-between;
      align-items: center;

      gap: 65px;
      margin-top: 50px;

      .compute-item {
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.015em;
        color: #9b9fac;

        h4 span {
          font-weight: bold;
          font-size: 36px;
          line-height: 44px;
          letter-spacing: -0.015em;

          color: #1053B9;
        }
      }
    }

    .spinner-box {
      margin-top: 65px;
    }
  }

  @include media-md {
    .container {
      flex-direction: column;
    }
    .purchase-box {
      margin-top: 30px;
      padding: 20px 15px 70px 15px;
      h2 {
        font-size: 30px;
      }
      h3 {
        margin-top: 53px;

        font-size: 16px;
        color: #000414;
      }
      .compute-result {
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;

        .compute-item {
          min-width: 130px;
          font-size: 12px;
          line-height: 15px;
          text-align: center;
        }
      }
    }
  }
}
